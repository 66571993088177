import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Card } from 'react-bootstrap';
import { AshCharacter } from '../components/ash-character';
import { AshTrace } from '../components/ash-trace';

interface IProps {
  slug: string;
  element: string;
}

export const AshProfileTeams: React.FC<IProps> = ({ slug, element }) => {
  return (
    <>
      <div className={`content-header  ${element}`}>
        <FontAwesomeIcon icon={faSquare} width="18" /> Detailed teams guide
      </div>
      {slug === 'kylin' && (
        <>
          <h5>Team 1</h5>
          <p>
            Leader: <strong>Cen Ying</strong>
          </p>
          <div className={`character-teams box Fire`}>
            <div className="team-header">
              <p>NON-STOP CHARGE</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="cen-ying"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Main DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="luke"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Sub DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="kylin"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role tank">Tank</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="boreas"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Support</div>
                </div>
              </div>
            </div>
          </div>
          <p>
            The ever-evolving summoner team has yet another update to its hall
            of possibilities. With Kylin as the frontline, this team can exert
            more pressure than ever. IMPORTANT! If you bring this team to a Dry
            or Normal weather stage, you will need to bring a source of water
            zone, such as Furay & Furney, Bubbles, or the upcoming Uni. After
            the initial setup, you can exchange them away for Luke or Freda. The
            support option recommended here is Boreas, as this team implies that
            you will use it on short stages or multi-team content, where Freda
            will often be busy with other teams. However, you can opt to use the
            combo Freda + Boreas in some occasions and Joint Training bosses in
            order to maximize Cen Ying’s ultimate uptime and damage.
          </p>
          <p>
            <strong>Back-up Options:</strong>
            <AshCharacter
              slug="freda"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="furay-and-furney"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="bubbles"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="boss"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </p>
          <p>
            <strong>Core Nexus Skills</strong>: Can’t Hurt to be Resourceful,
            Devotion, Class Link - Diamond, Getting the Groove
          </p>
          <p>
            <strong>Memory Traces</strong>
          </p>
          <ul className="bigger-margin">
            <li>
              <AshTrace name="Lucky Find" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Beneath The Deception" mode="data" onProfile />{' '}
            </li>
            <li>
              <AshTrace name="Born From Ashes" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Racing Night" mode="data" onProfile /> (if using
              Luke) or{' '}
              <AshTrace name="Silent Yesterday" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="My Vision is Clear!" mode="data" onProfile />{' '}
              (Core)
            </li>
            <li>
              <AshTrace name="Into the Fantasy" mode="data" onProfile /> (if
              using Boreas) or <AshTrace name="Company" mode="data" onProfile />
            </li>
          </ul>
          <p>
            <strong>Alternative Memory Traces:</strong>
          </p>
          <ul className="bigger-margin">
            <li>
              <AshTrace name="Emptiness" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Behind the Curtains" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Festive Night" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Your Yummiest Friend" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Karma" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="In-Between Time" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Growth" mode="data" onProfile />
            </li>
          </ul>
          <h5>Team 2</h5>
          <p>
            Leader: <strong>Scarlett</strong>
          </p>
          <div className={`character-teams box Fire`}>
            <div className="team-header">
              <p>MVIC: WIND</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="scarlett"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Main-DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="shiratori-azusa"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Sub-DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="kylin"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role tank">Tank</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="freda"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Support</div>
                </div>
              </div>
            </div>
          </div>
          <p>
            The Wind version of the MVIC summoner team, and excellent for
            multi-team content. As usual, start the stage by setting up a water
            zone if there isn’t one already, otherwise you can bring Furay &
            Furnay, Bubbles or Uni to do lay the ground and then switch to
            Freda. Being very straightforward, This is a simple case of stacking
            the two of the strongest damage multipliers combos in the game, and
            letting Scarlett do her job. The only downside is that this team may
            struggle with quick stacking of Inspire, so it may be a good idea to
            bring Longqing as well and swap Freda later. Or, in the case of
            Multi-team content, you can opt to using Longqing for this team and
            delegate Freda to another.
          </p>
          <p>
            <strong>Back-up Options:</strong>
            <AshCharacter
              slug="longqing"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="furay-and-furney"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="bubbles"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="begonia"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="felicio"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="boss"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </p>
          <p>
            <strong>Core Nexus Skills</strong>: Class Link - Triangle, Getting
            into the Swing, Bonus Time, Winning Chase
          </p>
          <p>
            <strong>Memory Traces</strong>
          </p>
          <ul className="bigger-margin">
            <li>
              <AshTrace name="Emptiness" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Blood Ruvia - Secret" mode="data" onProfile />{' '}
              (Core)
            </li>
            <li>
              <AshTrace name="Dusk Radiance" mode="data" onProfile /> (Core)
            </li>
            <li>
              <AshTrace name="Born from Ashes" mode="data" onProfile /> (Core)
            </li>
            <li>
              <AshTrace name="My Vision is Clear!" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Nightmare" mode="data" onProfile /> (if bringing
              Longqing)
            </li>
          </ul>
          <p>
            <strong>Alternative Memory Traces:</strong>
          </p>
          <ul className="bigger-margin">
            <li>
              <AshTrace name="Testing Limits" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Lucky Find" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Your Yummiest Friend" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Secret Melody" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Karma" mode="data" onProfile />
            </li>
          </ul>
          <h5>Team 3</h5>
          <p>
            Leader: <strong>Cyros</strong>
          </p>
          <div className={`character-teams box Fire`}>
            <div className="team-header">
              <p>EXPLOSIVE ALTERNATIVES</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="cyros"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Main-DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="skylar"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Sub-DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="kylin"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role tank">Tank</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="freda"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Support</div>
                </div>
              </div>
            </div>
          </div>
          <p>
            Yet another team who focuses on taking advantage of stacked bonuses
            of Elemental Res shred and Elemental reactions. Skylar can boost
            both her own damage and Kylin’s. With the arrival of the Memory
            Trace “Testing Limits”, which benefits both Cyros and Kylin, Cyros
            is most likely to outpace any of the current Lightning DPS options,
            while Skylar brings extra utility, CC, and helps maintain a steady
            trigger of Memory Trace skills to further boost his damage.
          </p>
          <p>
            <strong>Back-up Options:</strong>
            <AshCharacter
              slug="lydia"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="hassel"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="sorley"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="baili-tusu"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="longqing"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </p>
          <p>
            <strong>Core Nexus Skills</strong>: Class Link - Diamond (or
            Square), Energy Release, Strength Works Miracles, Devotion
          </p>
          <p>
            <strong>Memory Traces</strong>
          </p>
          <ul className="bigger-margin">
            <li>
              <AshTrace name="Emptiness" mode="data" onProfile /> or
              <AshTrace name="Lucky Find" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Testing Limits" mode="data" onProfile /> (Core)
            </li>
            <li>
              <AshTrace name="Into the Fantasy" mode="data" onProfile /> (Core)
            </li>
            <li>
              <AshTrace name="Company" mode="data" onProfile /> (Core)
            </li>
            <li>
              <AshTrace name="Born from Ashes" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="In-Between Time" mode="data" onProfile /> or
              <AshTrace name="Nightmare" mode="data" onProfile /> (if bringing
              Longqing and/or not using Lucky Find)
            </li>
          </ul>
          <p>
            <strong>Alternative Memory Traces:</strong>
          </p>
          <ul className="bigger-margin">
            <li>
              <AshTrace name="Lucky Find" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Your Yummiest Friend" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Behind the Curtains" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Karma" mode="data" onProfile />
            </li>
          </ul>
        </>
      )}
      {slug === 'boreas' && (
        <>
          <h5>Team 1</h5>
          <p>
            Leader: <strong>Cen Ying</strong>
          </p>
          <div className={`character-teams box Fire`}>
            <div className="team-header">
              <p>THE NEW SUMMONER TEAM</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="cen-ying"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="luke"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="yuqi"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role tank">Tank</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="boreas"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Support</div>
                </div>
              </div>
            </div>
          </div>
          <p>
            The new summoner team benefits greatly from Boreas’ +100% CRIT Rate
            buff to summons. Furthermore, content like Deep Abyss Nexus and
            Shriek Party Joint Training already come with plenty of Water Zones
            for Yuqi to trigger “My Vision is Clear!”. If that’s not the case,
            try bringing Furay & Furney or another Water Zone character to lay
            down the field before switching in to Luke.
          </p>
          <p>
            <strong>Back-up Options:</strong>
            <AshCharacter
              slug="skylar"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="freda"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="mars"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="furay-and-furney"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="sambheka"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />{' '}
            (And anyone of those elements and roles)
          </p>
          <p>
            <strong>Core Nexus Skills</strong>: Getting the Groove, Seed
            Overload, Life Harvest, Targeted Strike
          </p>
          <p>
            <strong>Memory Traces</strong>
          </p>
          <ul className="bigger-margin">
            <li>
              <AshTrace name="Lucky Find" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Beneath The Deception" mode="data" onProfile />{' '}
            </li>
            <li>
              <AshTrace name="Born From Ashes" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Racing Night" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="My Vision Is Clear!" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Into The Fantasy" mode="data" onProfile />
            </li>
          </ul>
          <p>
            <strong>Alternative Memory Traces:</strong>
          </p>
          <ul className="bigger-margin">
            <li>
              <AshTrace name="Happy Hours" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Behind The Curtains" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Dusk Radiance" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Codename Diversity" mode="data" onProfile />
            </li>
          </ul>
          <h5>Team 2</h5>
          <p>
            Leader: <strong>Ivana</strong>
          </p>
          <div className={`character-teams box Fire`}>
            <div className="team-header">
              <p>ETERNAL WINTER</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="ivana"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Main-DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="begonia"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Sub-DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="sambheka"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Elemental Reaction</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="boreas"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Support</div>
                </div>
              </div>
            </div>
          </div>
          <p>
            This team focuses on relentless crowd-control alongside stacking
            many buffs and debuffs both from its character kits and Memory
            Traces. You can also swap either Begonia or Sambheka for Cyros and
            turn it into a Melt team. It’s important to fill as many of your
            backup slots with Square Echomancers (Bulwarks, Vanguards,
            Strikers), and swap then in and out whenever possible in order to
            take maximum advantage of the Nexus Skill “Life Anthem”. This skill
            can greatly increase Ivana’s MST and consequently her overall damage
            output.
          </p>
          <p>
            <strong>Back-up Options:</strong>
            <AshCharacter
              slug="cen-ying"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="cyros"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="lorelle"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="mo-hongxiu"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="freda"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="longqing"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />{' '}
            (And anyone of those elements and roles)
          </p>
          <p>
            <strong>Core Nexus Skills</strong>: Strength Works Miracles, Life
            Anthem, Seed Overload
          </p>
          <p>
            <strong>Memory Traces</strong>
          </p>
          <ul className="bigger-margin">
            <li>
              <AshTrace name="Lucky Find" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Starlit Memories" mode="data" onProfile />{' '}
            </li>
            <li>
              <AshTrace name="Born from Ashes" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Dawn" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Company" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Into The Fantasy" mode="data" onProfile />
            </li>
          </ul>
          <p>
            <strong>Alternative Memory Traces:</strong>
          </p>
          <ul className="bigger-margin">
            <li>
              <AshTrace name="Happy Hours" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Emptiness" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Behind The Curtains" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Dusk Radiance" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Karma" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Hello, Visier" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Lightchaser" mode="data" onProfile />
            </li>
            <li>
              {' '}
              <AshTrace name="Nightmare" mode="data" onProfile />
            </li>
          </ul>
          <h5>Team 3</h5>
          <p>
            Leader: <strong>Cyros</strong>
          </p>
          <div className={`character-teams box Fire`}>
            <div className="team-header">
              <p>ELEMENTAL FEAST</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="cyros"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Main-DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="baili-tusu"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Sub-DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="skylar"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Sub-DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="boreas"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Support</div>
                </div>
              </div>
            </div>
          </div>
          <p>
            This team has the insane capability of quickly triggering several
            elemental reactions in quick succession. With Boreas in the team,
            the previous weakness of the Fire Team not working so well in Wet
            environment is turned into a strength. In Wet environments, Boreas
            and his buffs can consistently create Ice zones without the need of
            a Water zone previously placed. However, Cyros can consistently
            place Fire zones as usual, thus enabling combos of Melt, Explosion,
            and Electrolysis with the help of Skylar.
          </p>
          <p>
            <strong>
              Back-up Options to switch either Baili Tusu or Skylar include:
            </strong>
            <AshCharacter
              slug="begonia"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="ivana"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="lydia"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="hassel"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="freda"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            (And anyone of those elements and roles)
          </p>
          <p>
            <strong>Core Nexus Skills</strong>: Strength Works Miracles, Seed
            Chard, Seed Overload, Life Harvest
          </p>
          <p>
            <strong>Memory Traces</strong>
          </p>
          <ul className="bigger-margin">
            <li>
              <AshTrace name="Emptiness" mode="data" onProfile />{' '}
            </li>
            <li>
              <AshTrace name="Lucky Find" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Company" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Into The Fantasy" mode="data" onProfile />{' '}
            </li>
            <li>
              <AshTrace name="Born from Ashes" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Lightchaser" mode="data" onProfile />
            </li>
          </ul>
          <p>
            <strong>Alternative Memory Traces:</strong>
          </p>
          <ul className="bigger-margin">
            <li>
              <AshTrace name="Happy Hours" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Dusk Radiance" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Into A Paper Crane" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Karma" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Beneath The Deception" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Lawful Cage" mode="data" onProfile />
            </li>
          </ul>
        </>
      )}
      {slug === 'feng-qingxue' && (
        <>
          <h5>Team 1</h5>
          <p>
            Leader: <strong>Feng Qingxue</strong>
          </p>
          <div className={`character-teams box Fire`}>
            <div className="team-header">
              <p>MELTING SNOW</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="feng-qingxue"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Main DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="begonia"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Sub DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="freda"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Support</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="boreas"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Support</div>
                </div>
              </div>
            </div>
          </div>
          <p>
            As a Hyper-Carry, everything about Feng Qingxue’s team is centered
            around boosting her damage. The duo of Freda and Boreas are staple
            choices for their respective buffs, while Begonia is here to benefit
            from Boreas’ passives but, mainly, to increase Feng’s ASPD, while
            also being useful for breaking block gauges. Feng Qingxue has one of
            the lowest base ASPD in the game (0.25/s), but at the same time, she
            relies on her auto-attacks to charge her magazine and trigger
            Cloudburst. In some Dry stages, you can start with this formation
            right away, while for others it’s recommended to place someone to
            create Fire Zones first before swapping to Begonia. This will
            trigger the “Melt” elemental reaction (Ice + Fire), which reduces
            Enemies’ Basic DR up to a maximum of 60%. When in need of a good
            Frontline, Mo Hongxiu and Kylin are the prime choices for Feng. If
            you have (or can borrow) a Dupe 3 Tong, he will provide +15% DMG to
            Feng Qingxue even if he’s never deployed.
          </p>
          <p>
            <strong>Back-up Options:</strong>
            <AshCharacter
              slug="cyros"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="mo-hongxiu"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="reid"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="kylin"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="tong"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />{' '}
            ,
            <AshCharacter
              slug="hassel"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />{' '}
            ,
            <AshCharacter
              slug="pris"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />{' '}
            ,
            <AshCharacter
              slug="felicio"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />{' '}
            ,
            <AshCharacter
              slug="boss"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />{' '}
            (Tong if Dupe 3, Pris if using Secret Melody)
          </p>
          <p>
            <strong>Core Nexus Skills</strong>: Getting into the Swing, Seed
            Overload, Bonus Time, Shield Blessing, Winning Chase
          </p>
          <p>
            <strong>Memory Traces</strong>
          </p>
          <ul className="bigger-margin">
            <li>
              <AshTrace name="Emptiness" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Beneath The Deception" mode="data" onProfile />{' '}
            </li>
            <li>
              <AshTrace name="Born From Ashes" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Sirius" mode="data" onProfile /> (Core)
            </li>
            <li>
              <AshTrace name="Karma" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Your Yummiest Friend" mode="data" onProfile />
            </li>
          </ul>
          <p>
            <strong>Alternative Memory Traces:</strong>
          </p>
          <ul className="bigger-margin">
            <li>
              <AshTrace name="Secret Melody" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Lucky Find" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Company" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Muscle Building" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Home" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Into The Fantasy" mode="data" onProfile />
            </li>
          </ul>
          <h5>Team 2</h5>
          <p>
            Leader: <strong>Feng Qingxue</strong>
          </p>
          <div className={`character-teams box Fire`}>
            <div className="team-header">
              <p>REVIVAL: LITE</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="feng-qingxue"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Main-DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="su-xiao"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Support</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="freda"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Support</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="boreas"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Support</div>
                </div>
              </div>
            </div>
          </div>
          <p>
            Although the true “Revival Feng” won’t come live until Thea arrives
            next patch, you can try an alternate, “lite” version of it. The idea
            of this team is similar to Cen Ying’s Necromancer setup, with the
            first step being sacrificing Feng Qingxue as fast as possible in
            order to obtain massive amounts of boosts from Su Xiao and “Old
            Alley”. Furthermore, all the other Traces and skills recommended
            here are focused on maximizing bursts. Once Feng has died once, if
            the boss is somehow not dead, you can swap Su Xiao away and play the
            remaining of the fight like the regular Feng team.
          </p>
          <p>
            <strong>Back-up Options:</strong>
            <AshCharacter
              slug="cyros"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="mo-hongxiu"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="begonia"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="boss"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="tong"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            (Dupe 3)
          </p>
          <p>
            <strong>Core Nexus Skills</strong>: Seed Overload, Strike the Heart,
            Cheers to Friendship, Time to Reap: Elite
          </p>
          <p>
            <strong>Memory Traces</strong>
          </p>
          <ul className="bigger-margin">
            <li>
              <AshTrace name="Lucky Find" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Beneath The Deception" mode="data" onProfile />{' '}
            </li>
            <li>
              <AshTrace name="Born from Ashes" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Sirius" mode="data" onProfile /> (Core)
            </li>
            <li>
              <AshTrace name="Karma" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Old Alley" mode="data" onProfile /> (Core)
            </li>
          </ul>
          <p>
            <strong>Alternative Memory Traces:</strong>
          </p>
          <ul className="bigger-margin">
            <li>
              <AshTrace name="Your Yummiest Friend" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Into The Fantasy" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Company" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Home" mode="data" onProfile />
            </li>
          </ul>
        </>
      )}
      {slug === 'skylar' && (
        <>
          <h5>Team 1</h5>
          <p>
            Leader: <strong>Freda</strong>
          </p>
          <div className={`character-teams box Fire`}>
            <div className="team-header">
              <p>THE IAN SIBLINGS & FRIENDS</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="hassel"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Main-DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="skylar"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Sub-DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="boreas"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Support</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="freda"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Support</div>
                </div>
              </div>
            </div>
          </div>
          <p>
            Fast and relentless, this team focuses on quick attacks and ult
            spam, aided by Skylar’s 30% Lightning RES shred (45% if at dupe 1)
            and the massive amount of buffs from Boreas and Freda combined. If
            preferred, You can use Lydia instead of Hassel. Survivability should
            not be a problem considering Boreas’ shield and the consistent
            Electrolyzed reaction if this team is used in Wet Environment, but
            if you struggle with that, consider swapping Boreas for a tank.
          </p>
          <p>
            <strong>Back-up Options:</strong>
            <AshCharacter
              slug="mo-hongxiu"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="yuqi"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="lydia"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="begonia"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="felicio"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />{' '}
            (And anyone of those elements and roles)
          </p>
          <p>
            <strong>Core Nexus Skills</strong>: Shield Infusion, Cheers to
            Friendship, Seed Overload, Seed Charge
          </p>
          <p>
            NOTE: If you prioritize using Lydia as the main DPS, make sure to
            inherit the “Life Anthem” Nexus skill or use the “Starlit Memories”
            Memory Trace. The gist of it is that you will want to bring as many
            Square (Bulwark, Vanguard, Striker) Echomancers as possible to
            trigger that skill.
          </p>
          <p>
            <strong>Memory Traces</strong>
          </p>
          <ul className="bigger-margin">
            <li>
              <AshTrace name="Lucky Find" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Beneath The Deception" mode="data" onProfile />{' '}
            </li>
            <li>
              <AshTrace name="Born From Ashes" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Silent Yesterday" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Into The Fantasy" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Growth" mode="data" onProfile />
            </li>
          </ul>
          <p>
            <strong>Alternative Memory Traces:</strong>
          </p>
          <ul className="bigger-margin">
            <li>
              <AshTrace name="Happy Hours" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Behind The Curtains" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Karma" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Lawful Cage" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Secret Melody" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Muscle Building" mode="data" onProfile />
            </li>
          </ul>
          <h5>Team 2</h5>
          <p>
            Leader: <strong>Skylar</strong>
          </p>
          <div className={`character-teams box Fire`}>
            <div className="team-header">
              <p>“I CAN FIGHT TOO!”</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="skylar"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Main-DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="yuqi"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role tank">Tank</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="furay-and-furney"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Elemental Reaction</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="freda"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Support</div>
                </div>
              </div>
            </div>
          </div>
          <p>
            Skylar is far from the definition of a ‘main DPS’, but if you like
            her, she can definitely land a punch and clear content easily,
            especially when she’s in her favored environment. Speaking of
            environment, this team is perfectly suited to Wet Environments, and
            in some cases, such as the “Deep Abyss” Nexus path or the “Shriek
            Party” joint training, you can even opt to swap away Furay & Furney
            for another Echomancer of your choice. Skylar’s main damage source
            comes from her ultimate, so make sure to bring Freda and load Skylar
            with as many sources of CD reduction as possible.
          </p>
          <p>
            <strong>Back-up Options:</strong>
            <AshCharacter
              slug="lydia"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="boreas"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="longqing"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            (And anyone of those elements and roles)
          </p>
          <p>
            <strong>Core Nexus Skills</strong>: Class Link (Diamond), Life
            Anthem, Seed Overload, Seed Charge
          </p>
          <p>
            <strong>Memory Traces</strong>
          </p>
          <ul className="bigger-margin">
            <li>
              <AshTrace name="Beneath The Deception" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Lucky Find" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Starlit Memories" mode="data" onProfile />{' '}
            </li>
            <li>
              <AshTrace name="Born from Ashes" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="My Vision Is Clear!" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Into The Fantasy" mode="data" onProfile />
            </li>
          </ul>
          <p>
            <strong>Alternative Memory Traces:</strong>
          </p>
          <ul className="bigger-margin">
            <li>
              <AshTrace name="Happy Hours" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Behind The Curtains" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Nightmare" mode="data" onProfile />
            </li>
          </ul>
          <h5>Team 3</h5>
          <p>
            Leader: <strong>Cyros</strong>
          </p>
          <div className={`character-teams box Fire`}>
            <div className="team-header">
              <p>EXPLOSIVE BIRDIE</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="cyros"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Main-DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="skylar"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Sub-DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="mo-hongxiu"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role tank">Tank</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="freda"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Support</div>
                </div>
              </div>
            </div>
          </div>
          <p>
            Although Skylar can make the most of her debuffs on Wet Environments
            and Lightning teams, she can also be a great asset to Fire teams and
            Dry environments, aiding with much needed anti-air support, crowd
            control and creating Explosion reactions. A solid frontline like Mo
            Hongxiu can help Skylar relax safely at the back, creating a
            well-rounded team when paired with Cyros’ offensive capabilities and
            Freda’s buffs.
          </p>
          <p>
            <strong>Back-up Options include:</strong>
            <AshCharacter
              slug="baili-tusu"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="reid"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="roar"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="lydia"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="longqing"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="boreas"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            (And anyone of those elements and roles)
          </p>
          <p>
            <strong>Core Nexus Skills</strong>: Strength Works Miracles, Seed
            Chard, Seed Overload, Life Harvest
          </p>
          <p>
            <strong>Memory Traces</strong>
          </p>
          <ul className="bigger-margin">
            <li>
              <AshTrace name="Emptiness" mode="data" onProfile />{' '}
            </li>
            <li>
              <AshTrace name="Lucky Find" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Company" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Into The Fantasy" mode="data" onProfile />{' '}
            </li>
            <li>
              <AshTrace name="Born from Ashes" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Lightchaser" mode="data" onProfile />
            </li>
          </ul>
          <p>
            <strong>Alternative Memory Traces:</strong>
          </p>
          <ul className="bigger-margin">
            <li>
              <AshTrace name="Happy Hours" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Dusk Radiance" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Into A Paper Crane" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Karma" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Beneath The Deception" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Lawful Cage" mode="data" onProfile />
            </li>
          </ul>
        </>
      )}
      {slug === 'baili-tusu' && (
        <>
          <h5>Team 1</h5>
          <p>
            Leader: <strong>Baili Tusu</strong>
          </p>
          <div className={`character-teams box Fire`}>
            <div className="team-header">
              <p>FIRE TEAM - REKINDLED</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="baili-tusu"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="cyros"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="mo-hongxiu"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role tank">Tank</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="freda"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Support</div>
                </div>
              </div>
            </div>
          </div>
          <p>
            This team has Baili Tusu as leader and focuses on having a strong
            frontline with maximum exploit of stacking Elemental RES debuffs,
            Extra DMG and CRITs. The ideal is to have Mo Hongxiu with Dupe 1 for
            the 20% Fire RES reduction, but if you don’t have that, she can
            still hold the line and prevent Baili Tusu and Cyros from taking
            unnecessary damage. This allows Freda to keep her Buff mode up more
            often. The choice for Tusu as leader here comes from his Leader
            skill, “Learning from Mistakes”. It can stack up to +75% Extra DMG
            permanently, being a more stable version than Cyros’ Leader skill if
            you’re focusing the main bulk of damage on Baili Tusu.
          </p>
          <p>
            <strong>Back-up Options:</strong>
            <AshCharacter
              slug="yuqi"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="lydia"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="scarlett"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="felicio"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="reid"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="roar"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="su-xiao"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </p>
          <p>
            <strong>Memory Traces</strong>
          </p>
          <ul className="bigger-margin">
            <li>
              <AshTrace name="Emptiness" mode="data" onProfile /> or{' '}
              <AshTrace name="Into A Paper Crane" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Codename Diversity" mode="data" onProfile /> (if
              you don’t have Mo Hongxiu, use Roar to trigger this one)
            </li>
            <li>
              <AshTrace name="Company" mode="data" onProfile /> or{' '}
              <AshTrace name="Behind the Curtains" mode="data" onProfile /> or{' '}
              <AshTrace name="Sky Fall" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Festive Night" mode="data" onProfile /> or
              <AshTrace name="Dusk Radiance" mode="data" onProfile /> (if Dupe 1
              Mo Hongxiu)
            </li>
            <li>
              <AshTrace name="Born from Ashes" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Growth" mode="data" onProfile /> or{' '}
              <AshTrace name="Karma" mode="data" onProfile /> or{' '}
              <AshTrace name="Lightchaser" mode="data" onProfile />{' '}
            </li>
          </ul>
          <h5>Team 2</h5>
          <p>
            Leader: <strong>Cyros</strong>
          </p>
          <div className={`character-teams box Fire`}>
            <div className="team-header">
              <p>A SONG OF MELT</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="cyros"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="baili-tusu"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="begonia"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Elemental Reaction</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="longqing"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Support</div>
                </div>
              </div>
            </div>
          </div>
          <p>
            If you like living dangerously and like the idea of melting your
            enemies both through Elemental Reactions and literally, this team is
            for you. For this team, Begonia’s role goes beyond buffing ASPD: Ice
            DMG on Fire Zone (or vice-versa) trigger the elemental reaction
            “Melt”, which can stack to up to a reduction of 60% DR on affected
            enemies. Add this to consistent crits and explosive bursts, and your
            enemies will melt in no time. Longqing is the ideal choice here to
            stack Inspire faster as well as to grant extra survivability through
            her barriers and more intensive healing.
          </p>
          <p>
            <strong>Back-up Options:</strong>
            <AshCharacter
              slug="freda"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="zodivin-azure"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="scarlett"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="felicio"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="reid"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="roar"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="su-xiao"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
            ,
            <AshCharacter
              slug="bellia"
              mode="inline"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </p>
          <p>
            <strong>Memory Traces</strong>
          </p>
          <ul className="bigger-margin">
            <li>
              <AshTrace name="Emptiness" mode="data" onProfile /> or{' '}
              <AshTrace name="Into A Paper Crane" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Beneath the Deception" mode="data" onProfile />{' '}
              (Lv 1 Awakening Skill only) or
              <AshTrace name="Growth" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Company" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Behind the Curtains" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Nightmare" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Born from Ashes" mode="data" onProfile />
            </li>
          </ul>
          <h5>Team 3</h5>
          <p>
            Leader: <strong>Cyros</strong>
          </p>
          <div className={`character-teams box Fire`}>
            <div className="team-header">
              <p>EXPLOSION TEAM</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="cyros"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Elemental Zone</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="lydia"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="baili-tusu"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="longqing"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Support</div>
                </div>
              </div>
            </div>
          </div>
          <p>
            This team is built around the “Life Anthem” skill, which can grant
            lots of passive healing and insane amounts of MST to skyrocket
            Lydia’s overall DMG output. In addition, Cyros can provide large
            elemental zones to guarantee even more damage through the Explosion
            elemental reaction, while Baili Tusu helps split the frontline and
            trigger certain Trace skills through his core mechanics.
          </p>
          <p>
            <strong>Back-up Options:</strong> Any Square-type Echomancer
          </p>
          <p>
            <strong>Memory Traces</strong>
          </p>
          <ul className="bigger-margin">
            <li>
              <AshTrace name="Starlit Memories" mode="data" onProfile /> [CORE]
              (Pick the Nexus Skill “Life Anthem” at least at level 2 and fill
              all your backup slots with Square-type Echomancers. They don’t
              need to be built.)
            </li>
            <li>
              <AshTrace name="Born from Ashes" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Emptiness" mode="data" onProfile /> or{' '}
              <AshTrace name="Into A Paper Crane" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Company" mode="data" onProfile />
            </li>
            <li>
              <li>
                <AshTrace name="Behind the Curtains" mode="data" onProfile /> or{' '}
                <AshTrace name="Festive Night" mode="data" onProfile />
              </li>
              <AshTrace name="Nightmare" mode="data" onProfile /> or
              <AshTrace name="Elegy At Dusk" mode="data" onProfile /> (if using
              Freda instead of Longqing)
            </li>
          </ul>
        </>
      )}
      {slug === 'scarlett' && (
        <>
          <h5>Leader: Scarlett</h5>
          <div className={`character-teams box Wind`}>
            <div className="team-header">
              <p>RELENTLESS ATTACKS</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="scarlett"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Main DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="shiratori-azusa"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Sub-DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="begonia"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role tank">Sub-DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="freda"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Support</div>
                </div>
              </div>
            </div>
          </div>
          <p>
            A very aggressive but self-reliant team. It maximizes damage
            potential from all fronts, with Scarlett reducing enemy DR, Azusa
            reducing enemy’s Elemental RES and Begonia increasing the team’s
            ASPD. Freda adds the cherry on the cake with her myriad of buffs and
            healing. During most of the fight, Azusa and Begonia should split
            the frontline to make managing damage easier. When dealing with
            elite enemies or bosses, Scarlett takes the frontline instead, by
            activating her skill “Relentless Pursuit”.
          </p>
          <div className={`character-teams box Wind`}>
            <div className="team-header">
              <p>DEFENSIVE PURSUIT</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="scarlett"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Main DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="shiratori-azusa"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Sub DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="lorelle"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role tank">Tank</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="longqing"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Support</div>
                </div>
              </div>
            </div>
          </div>
          <p>
            A well-balanced team with a solid frontline and a more defensive
            approach. In this team, instead of focusing on ASPD, Lorelle will
            provide Extra DMG bonus and block gauge breaking through her
            Polyphony passive, while Longqing can quickly stack Inspire with
            “Nightmare”. She can be switched to Freda after reaching full
            Inspire stacks for more ATK buffs.
          </p>
          <h5>Memory Traces Recommendations</h5>
          <ul className="bigger-margin">
            <li>
              <AshTrace name="Blood Ruvia - Secret" mode="data" onProfile />{' '}
              [Core]
            </li>
            <li>
              <AshTrace name="Dusk Radiance" mode="data" onProfile /> [Core]
            </li>
            <li>
              <AshTrace name="Born From Ashes" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Secret Melody" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Going Off-Course" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Karma" mode="data" onProfile />
            </li>
          </ul>
          <h5>Alternatives and/or Budget options</h5>
          <ul className="bigger-margin">
            <li>
              <AshTrace name="Muscle Building" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Beneath The Deception" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Emptiness" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Happy Hours" mode="data" onProfile /> (Replaces
              “Secret Melody”)
            </li>
            <li>
              <AshTrace name="Home" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Dawn" mode="data" onProfile /> (if using Sambheka)
            </li>
            <li>
              <AshTrace name="My Vision Is Clear!" mode="data" onProfile /> (If
              using Yuqi + Water Zones)
            </li>
            <li>
              <AshTrace name="Behind The Curtains" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Curiosity Of A Hailinian" mode="data" onProfile />{' '}
              (For the Triangle skills, but becomes a lot better with Thea)
            </li>
            <li>
              <AshTrace name="Growth" mode="data" onProfile />
            </li>
          </ul>
        </>
      )}
      {slug === 'cen-ying' && (
        <>
          <div className={`character-teams box Physical`}>
            <div className="team-header">
              <p>SUMMONER TEAM</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="cen-ying"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Main DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="luke"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Sub DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="mo-hongxiu"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role tank">Tank</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="freda"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Support</div>
                </div>
              </div>
            </div>
          </div>
          <p>
            A straightforward team. Cen Ying and Luke are obvious picks and need
            no introduction. Mo Hongxiu can create barriers, displace and group
            up enemies, helping to control the battlefield and direct burst
            windows.Freda is another easy pick for her buffs and ability to
            lower her teammates’ cooldowns, which helps with Cen Ying’s problem
            of having a long cooldown on her Seed Skill.
          </p>
          <div className={`character-teams box Physical`}>
            <div className="team-header">
              <p>ALT. SUMMONER TEAM</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="cen-ying"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Main DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="sephonis"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Sub DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="lorelle"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role tank">Tank</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="longqing"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Support</div>
                </div>
              </div>
            </div>
          </div>
          <p>
            Lorelle has the ability to draw DMG Taken to herself while on
            Defense Mode, and buff all allies attacks when in Attack mode. She’s
            also useful for her gauge breaking capability, something the
            Summoner team usually struggles with. She can be paired with
            Sephonis. As a fellow summoner and Corrosion Echomancer, Sephonis
            benefits from most traces in the build and synergizes with Lorelle.
            If you’re pulling for Cen Ying, chances are you’ll get at least one
            copy of her.
          </p>
          <div className={`character-teams box Physical`}>
            <div className="team-header">
              <p>BUDGET / NECROMANCER TEAM</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="cen-ying"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Main DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="gauya"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Sub DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="tian-ruo"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role tank">Tank</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="su-xiao"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Support</div>
                </div>
              </div>
            </div>
          </div>
          <p>
            The usage of this team match is that Cen Ying stands in the front
            line to die once; Su Xiao can directly resurrect and then buff her
            ATK and CRIT until the end of the battle. Gauya's “Soul Queller" is
            considered a summon, and he’s also able to temporarily summon a
            ghost of an ally who died to fight.
          </p>
          <h5>Memory Traces</h5>
          <ul className="bigger-margin">
            <li>
              <AshTrace name="Beneath the Deception" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Behind the Curtains" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Born from Ashes" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Bad Soil Bloom" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Nightmare" mode="data" onProfile /> (if with
              Longqing or Tian Ruo)
            </li>
            <li>
              <AshTrace name="Karma" mode="data" onProfile />
            </li>
          </ul>
          <h5>Alternatives and/or Budget options</h5>
          <ul className="bigger-margin">
            <li>
              <AshTrace name="Happy Hours" mode="data" onProfile /> (Replaces
              “Bad Soil Bloom”)
            </li>
            <li>
              <AshTrace name="Traces in the Museum" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Racing Night" mode="data" onProfile /> (if with
              Luke)
            </li>
            <li>
              <AshTrace name="Dawn" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Double Attraction" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Codename Diversity" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Wish in the Rain" mode="data" onProfile />
            </li>
          </ul>
        </>
      )}
      {slug === 'lorelle' && (
        <>
          <div className={`character-teams box Corrosion`}>
            <div className="team-header">
              <p>FULL CORROSION</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="caroline"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="gina"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="lorelle"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role tank">Tank</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="freda"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Support</div>
                </div>
              </div>
            </div>
          </div>
          <p>
            Our main team has <strong>Lorelle</strong> as Tank, increasing the
            team's survivability and damage potential, Caroline as DPS with some
            healing on the side, and <strong>Gina</strong> for Burst Damage and
            Anti-Projectile capabilities. All three provide Corrosion
            Accumulation which increases the team's damage output. Freda
            provides consistent healing and buffs. <strong>Aceo</strong> and{' '}
            <strong>Sephonis</strong> can be used as backups or switch in with
            Gina in case you don’t have her.
          </p>
          <h5>Leader: Caroline</h5>
          <p>
            <strong>Memory Traces</strong>
          </p>
          <ul className="bigger-margin">
            <li>
              <AshTrace name="Hidden Blade" mode="data" onProfile />{' '}
            </li>
            <li>
              <AshTrace name="Shadows Of The Alley" mode="data" onProfile /> or{' '}
              <AshTrace name="Nightmare" mode="data" onProfile /> (if with
              Longqing) or <AshTrace name="Happy Hours" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Growth" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Muscle Building" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Emptiness" mode="data" onProfile /> or{' '}
              <AshTrace name="Behind the Curtains" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Illusion of Flowers" mode="data" onProfile /> or{' '}
              <AshTrace name="Karma" mode="data" onProfile /> if with Lorelle as
              Leader
            </li>
          </ul>
          <div className={`character-teams box Corrosion`}>
            <div className="team-header">
              <p>ICE WITH SPICE</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="ivana"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Main DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="begonia"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Sub DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="lorelle"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role tank">Tank</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="lydia"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Elemental Reaction</div>
                </div>
              </div>
            </div>
          </div>
          <p>
            Better suited for [Wet Environment]s, this team is a proof of
            concept of Lorelle’s versatility and ability to enable all sorts of
            team compositions. Begonia’s ASPD buffs greatly enhance Ivana’s and
            Lydia’s performances, both heavily tied to their auto-attacks. With
            Lorelle diverting 30% of damage and Lydia easily triggering
            Electrolyzed, this team can survive just fine without a healer while
            dishing lots of damage. You can also opt to swap Begonia or Lydia
            for <strong>Sambheka</strong> to enable the team in non-Wet
            environments, and always bring a healer as backup in case things go
            south.{' '}
            <strong>
              If you don’t feel secure, using a healer from the start and
              treating this as a Freeze team is also a valid approach.
            </strong>
          </p>
          <h5>Leader: Ivana</h5>
          <p>
            <strong>Memory Traces</strong>
          </p>
          <ul className="bigger-margin">
            <li>
              <AshTrace name="Shadows of the Alley" mode="data" onProfile /> or{' '}
              <AshTrace name="Happy Hours" mode="data" onProfile />{' '}
            </li>
            <li>
              <AshTrace name="Traces in the Museum" mode="data" onProfile /> or{' '}
              <AshTrace name="Beneath the Deception" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Emptiness" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Hello, Visier" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Behind the Curtains" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Growth" mode="data" onProfile />
            </li>
          </ul>
          <div className={`character-teams box Corrosion`}>
            <div className="team-header">
              <p>GOTTA GO FAST</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="begonia"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Main DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="felicio"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Sub DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="lorelle"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role tank">Tank</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="freda"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Support</div>
                </div>
              </div>
            </div>
          </div>
          <p>
            A Beginner friendly team fully focused on getting as much ASPD and
            Cooldown reduction as possible. These four characters all stack
            buffs from ATK to CRIT to Extra DMG to the aforementioned ASPD and
            Cooldown reduction, and are all focused on auto-attack. That makes
            this team very forgiving for beginners who will still maintain their
            output even if their run out of skill uses. Aside from that, the
            team has everything covered, from block gauge breaking to
            interception of projectiles and Airborne enemies.
          </p>
          <div className={`character-teams box Corrosion`}>
            <div className="team-header">
              <p>BUDGET EXPLOSION</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="ms-lew"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Main DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="reid"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Fire Zone</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="lorelle"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role tank">Tank</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="su-xiao"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Support</div>
                </div>
              </div>
            </div>
          </div>
          <p>
            A team composed mainly of free characters and Memory Traces +
            Lorelle. It’s nevertheless powerful enough to carry you all the way
            through the game’s main content, and is also a very forgiving team
            for players who are learning the game. Reid can heal himself fully
            once per battle, and Su Xiao can save an ally from death once per
            battle as well (twice once she gets her third dupe). As you progress
            through the game, you can upgrade your team comp with higher rarity
            characters that will perform the same functions but with higher
            output, such as Lydia, Hassel, Cyros and Freda.
          </p>
          <ul className="bigger-margin">
            <li>
              <AshTrace name="Happy Hours" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Codename Diversity" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Beneath the Deception" mode="data" onProfile /> or{' '}
              <AshTrace name="Into a Paper Crane" mode="data" onProfile />{' '}
              (“Gotta go Fast” team) or{' '}
              <AshTrace name="Deadline" mode="data" onProfile /> (“Budget
              Explosion” team)
            </li>
            <li>
              <AshTrace name="Behind the Curtains" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Karma" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Blows? Bonds?" mode="data" onProfile />
            </li>
          </ul>
        </>
      )}
    </>
  );
};
